<template>
  <v-card>
    <v-container>
      <v-card-title class="mt-n3">
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row class="mt-n6">
            <!-- Fecha -->
            <v-col cols="3" md="3">
              <v-menu
                ref="vigencia-desde"
                v-model="menuFechaOp"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaOpSelected"
                    label="Fecha"
                    :append-icon="calendarIcon"
                    v-bind="attrs"
                    autocomplete="not"
                    hint="Formato DD/MM/AAAA"
                    @blur="fechaOp = helpers.parseDateToIso(fechaOpSelected)"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    v-on="on"
                    :rules="rules.required.concat(rules.validDate)"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaOp"
                  no-title
                  @change="fechaOpSelected = helpers.formatDate(fechaOp)"
                  @input="menuFechaOp = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- Observacion -->
            <v-col cols="6">
              <v-text-field
                v-model.trim="observacion"
                type="text"
                :label="ObservacionLabel"
                dense
                outlined
                :rules="esEntrega ? rules.required.concat([rules.maxLength(observacion, 250), rules.requiredTrim(observacion)]) : rules.required.concat([rules.maxLength(observacion, 200), rules.requiredTrim(observacion)])"
              ></v-text-field>
            </v-col>
            <!-- Uusuario -->
            <v-col cols="3" v-if="showUsuario">
              <v-text-field
                v-model.trim="usuarioNom"
                type="text"
                label="Usuario"
                dense
                filled
                disabled
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="mt-n11">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import helpers from "@/utils/helpers.js";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
export default {
  name: "EntregaReciboOP",
  directives: { mask },
  props: {
    comprobantesToEntregaReciboOP: {
      type: Array,
    },
    isEntregaOrRecibo: {
      type: String,
    },
  },
  data: () => ({
    rules: rules,
    helpers: helpers,
    formEditTitle: "",
    calendarIcon: enums.icons.CALENDAR,
    usuarioNom: null,
    observacion: null,
    ObservacionLabel: null,
    menuFechaOp: false,
    esEntrega: false,
    fechaOpSelected: null,
    fechaOp: null,
    isFormValid: false,
    showUsuario: false,
  }),
  created() {
    this.setPantalla();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getEntregaReciboDeOP: "proveedores/getEntregaReciboDeOP",
      saveEntregaReciboDeOP: "proveedores/saveEntregaReciboDeOP",
    }),
    async setPantalla() {
      if (this.isEntregaOrRecibo == "Entrega"){
        this.esEntrega = true;
        this.formEditTitle = "Registro de entrega de OP";
        this.ObservacionLabel = "Observación";
      }else{
        this.esEntrega = false;
        this.formEditTitle = "Registro de recibo de OP";
        this.ObservacionLabel = "N° de recibo";
      }
      this.showUsuario = false;
      if (this.comprobantesToEntregaReciboOP.length == 1) {
        const response = await this.getEntregaReciboDeOP(
          this.comprobantesToEntregaReciboOP[0].movProvId
        );
        if (this.isEntregaOrRecibo == "Entrega") {
          if (response.entregaFecha != null) {
            this.fechaOp = this.parseDateToIso(response.entregaFecha);
            this.fechaOpSelected = response.entregaFecha;
          }
          this.observacion = response.entregaObservacion;
          this.usuarioNom = response.entregaUsuario;
          if(response.entregaUsuario == null){
            this.showUsuario = false;
          }else{
            this.showUsuario = true;
          }
        } else {
          if (response.reciboFecha != null) {
            this.fechaOp = this.parseDateToIso(response.reciboFecha);
            this.fechaOpSelected = response.reciboFecha;
          }
          this.observacion = response.reciboObservacion;
          this.usuarioNom = response.reciboUsuario;
          if(response.reciboUsuario == null){
            this.showUsuario = false;
          }else{
            this.showUsuario = true;
          }
        }
      }
    },
    async canSave() {
      this.isFormValid = false;
      const arrayIds = this.comprobantesToEntregaReciboOP.map(
        (x) => x.movProvId
      );
      const entFacId = this.comprobantesToEntregaReciboOP[0].entFacIdXTComp;
      const data = {
        fecha: this.fechaOp,
        obs: this.observacion,
        tipo: this.isEntregaOrRecibo,
        entFacId: entFacId,
        mProvIds: arrayIds,
      };
      try {
        const response = await this.saveEntregaReciboDeOP(data);
        if (response) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch (error) {
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReloadRegistro");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
  },
};
</script>

<style scoped>
</style>