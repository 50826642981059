<template>
  <v-container class="p-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row class="mb-n3">
          <v-col cols="12" md="10" class="mt-5">
            <PageHeader :title="title" />
          </v-col>
          <v-col cols="12" md="2" align="end" class="mt-9 pt-0">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="to-right"
                  v-on="on"
                  icon
                  v-bind="attrs"
                  @click="goToConfigOrdenesPago()"
                  ><v-icon size="30px">{{ settingsIcon }}</v-icon>
                </v-btn>
              </template>
              <span>Acceder a la configuración de órdenes de pago</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="pt-1"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4 mt-n5">
                  <!-- Empresa -->
                  <v-col cols="12" md="4" sm="6" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresas"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                      autocomplete="off"
                      label="Empresa"
                      hint="Nombre"
                      persistent-hint
                      @change="empresaSelectedChange()"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Proveedores -->
                  <v-col cols="12" md="2" class="p-0 pl-1">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      label="Código proveedor"
                      v-model.trim="provCod"
                      :rules="[rules.maxLength(provCod, 10)]"
                      @change="
                        {
                          buscarProveedor();
                        }
                      "
                      autocomplete="off"
                      hide-details=""
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="toggleModalBusquedaProv"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar proveedor por nombre, CUIT o código</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- DATOS PROVEEDOR -->
                  <v-col cols="12" md="4" class="p-0 pl-1">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      disabled
                      readonly
                      label="Proveedor"
                      hide-details=""
                      :loading="proveedoresLoading"
                      v-model.trim="provNom"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Tipo de valor -->
                  <v-col cols="4" md="4" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="tipoValorSelected"
                      :items="tiposValor"
                      item-text="value"
                      item-value="id"
                      outlined
                      return-object
                      clearable
                      dense
                      autocomplete="off"
                      label="Tipo de valor"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de fecha -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="tiposFechas"
                      :rules="rules.required"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Tipo de fecha (*)"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Fecha desde -->
                  <v-col cols="2" md="2" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          @change="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="2" md="2" sm="6" class="py-0">
                    <v-menu
                      ref="menu-fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta (*)"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          dense
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @keyup.enter="isFormValid && applyFilters"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          @change="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- Entrega de OP -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="entregaOPSelected"
                      :items="entregasOP"
                      return-object
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Entrega de OP"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Entrega de recibo -->
                  <v-col cols="3" md="3" sm="6" class="py-0">
                    <v-autocomplete
                      v-model="entregaReciboSelected"
                      :items="entregasRecibo"
                      return-object
                      :rules="rules.required"
                      item-text="value"
                      item-value="id"
                      outlined
                      dense
                      autocomplete="off"
                      label="Registro de recibo"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Solo enviable por email -->
                  <v-col cols="2" md="2" class="py-0 my-0 mt-n3">
                    <v-switch
                      v-model="enviableMail"
                      class="mt-1"
                      :disabled="this.empresaSelected == null"
                      @change="enviableMailChange()"
                      label="Solo enviable por email"
                    ></v-switch>
                  </v-col>
                  <v-col cols="1" class="py-2">
                    <v-tooltip right max-width="30%">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="primary" v-bind="attrs" v-on="on">
                          {{ infoIcon }}
                        </v-icon>
                      </template>
                      <strong> Configuración para envío por email:</strong>
                      <ul>
                        <li>Email del proveedor</li>
                        <li>Formato de email, (asunto, cuerpo, remitente)</li>
                        <li>Formato de impresión de la OP</li>
                      </ul>
                      <strong> * Solo si está seleccionada una empresa</strong>
                    </v-tooltip>
                  </v-col>
                  <!-- solo enviados -->
                  <v-col
                    cols="3"
                    class="py-0 my-0 mt-n2"
                    v-if="this.enviableMail && this.empresaSelected"
                  >
                    <v-switch
                      class="mt-1"
                      v-model="soloNoEnviado"
                      label="Solo no enviados"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- sucursal op -->
                  <v-col cols="2" class="pb-0 py-0">
                    <v-text-field
                      v-model.trim="sucursalOp"
                      type="text"
                      label="Sucursal"
                      :rules="rules.nonZeroNotRequired"
                      dense
                      v-mask="'#####'"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <!-- numero op -->
                  <v-col cols="2" class="pb-0 py-0">
                    <v-text-field
                      v-model.trim="numeroDeOp"
                      type="text"
                      label="Número"
                      :rules="rules.nonZeroNotRequired"
                      dense
                      outlined
                      v-mask="'########'"
                    ></v-text-field>
                  </v-col>
                  <!-- solo sin n transferencia o cheque -->
                  <!-- <v-col cols="4" class="py-0 my-0 mt-n2">
                    <v-switch
                      class="mt-1"
                      v-model="soloSinTransfe"
                      label="Sólo sin N° de transferencia/cheque"
                    ></v-switch>
                  </v-col> -->
                </v-row>
                <v-row> </v-row>
                <v-row justify="end" class="mt-n6">
                  <v-col class="py-0" align="left">
                    <v-subheader>(*) Datos obligatorios</v-subheader>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="ordenesDePago"
            item-key="idConsultaOp"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            v-model="registrosSeleccionados"
            @toggle-select-all="selectAllToggle"
            :show-select="ordenesDePago.length > 0"
          >
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    :value="isSelected"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <span>Seleccionar registro</span>
              </v-tooltip>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3"></v-col>
                  <v-col cols="2" class="text-right">
                    <v-btn
                      class="to-right"
                      color="primary"
                      @click="goToPagosMasivos()"
                      small
                      >Pagos masivos</v-btn
                    >
                  </v-col>
                  <v-col cols="1" class="text-right">
                    <v-btn
                      class="to-right"
                      small
                      color="primary"
                      @click="goToOrdenPago()"
                      >Nuevo</v-btn
                    >
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canSee">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="
                      seeComprobante(item.movProvId, item.proveedor.email)
                    "
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver orden de pago</span>
              </v-tooltip>
              <template>
                <v-menu
                  transition="scale-transition"
                  bottom
                  origin="center center"
                >
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-icon v-bind="attrs" size="20" v-on="menu">
                      {{ editIcon }}
                    </v-icon>
                  </template>
                  <v-list dense>
                    <v-list-item
                      v-for="i in editOpOptions"
                      :key="i.id"
                      link
                      @click="navigateEditComprobanteOp(i.id, item)"
                    >
                      <v-list-item-title>{{ i.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToEditOrdenPago(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar orden de pago</span>
              </v-tooltip> -->
              <v-tooltip left v-if="canEliminarOp">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToDeleteComprobante(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar orden de pago</span>
              </v-tooltip>
              <v-tooltip left v-if="canRevertirOp || canEntregaRecibo">
                <template v-slot:activator="{ on, attrs }">
                  <v-menu offset-y>
                    <template
                      v-slot:activator="{ on: menuOn, attrs: menuAttrs }"
                    >
                      <v-icon
                        v-bind="{ ...attrs, ...menuAttrs }"
                        v-on="{ ...on, ...menuOn }"
                        size="20"
                      >
                        {{ verticalPointsIcon }}
                      </v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item v-if="canRevertirOp" @click="goToRevertOp(item)">
                        <v-list-item-title>Revertir orden de pago</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="canEntregaRecibo" @click="goToEntregaIndividual(item)">
                        <v-list-item-title>Entrega de OP</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="canEntregaRecibo" @click="goToReciboIndividual(item)">
                        <v-list-item-title>Recibo de OP</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>Más acciones</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <!-- Exportar a Excel -->
          <v-col cols="1" md="1" class="py-4 mr-3">
            <v-menu transition="scale-transition" bottom origin="center center">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn v-bind="attrs" small color="primary" v-on="menu"
                  >Exportar</v-btn
                >
              </template>

              <v-list dense>
                <v-list-item
                  v-for="item in opcionesMenuExportar"
                  :key="item.id"
                  :disabled="item.disabled"
                  link
                  @click="selectOpcionMenuExportar(item.id)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <!-- Menu desplegable opciones para email -->
          <v-col cols="1" md="1" class="py-4 mr-n3">
            <v-menu transition="scale-transition" bottom origin="center center">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn v-bind="attrs" small color="primary" v-on="menu"
                  >Email</v-btn
                >
              </template>

              <v-list dense>
                <v-list-item
                  v-for="item in opcionesMenuEmail"
                  :key="item.id"
                  :disabled="item.disabled"
                  link
                  @click="selectOpcionMenuEmail(item.id)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <!-- menu desplegable opciones generales -->
          <v-col cols="6" md="6" class="py-4">
            <v-menu transition="scale-transition" bottom origin="center center">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-btn v-bind="attrs" small color="primary" v-on="menu"
                  >...</v-btn
                >
              </template>

              <v-list dense>
                <v-list-item
                  v-for="item in opcionesMenuGrales"
                  :key="item.id"
                  :disabled="item.disabled"
                  link
                  @click="selectOpcionMenuGrales(item.id)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="2" :md="showHelp ? 2 : 2" sm="2" class="mt-0">
            <v-text-field
              v-if="tipoValorSelected"
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input right-input"
              label="Total tipo de valor"
              v-model="totalTipoValor"
            ></v-text-field>
          </v-col>
          <v-col cols="2" :md="showHelp ? 2 : 2" sm="2" class="mt-0">
            <v-text-field
              type="text"
              outlined
              dense
              disabled
              filled
              readonly
              class="theme--light.v-input input right-input"
              label="Total de OP"
              v-model="totalOP"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalDatosXPantalla"
      v-model="modalDatosXPantalla"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="primary--text"
            >Completar datos pedidos por pantalla</span
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersDatosXPantalla"
            item-key="envioConfiguracionEstandarDetalleId"
            dense
            hide-default-footer
            :items="datosXPatalla"
            class="elevation-1"
          >
            <template v-slot:[`item.campoValor`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-edit-dialog
                      v-model="item.campoValor"
                      large
                      @open="openEditDialog(item.campoValor)"
                      cancel-text="Cancelar"
                      save-text="Guardar"
                      persistent
                      @save="saveEditValor(item)"
                      @cancel="cancelEditValor(item)"
                    >
                      {{ item.campoValor }}
                      <v-icon
                        :color="
                          item.campoValor != null && item.campoValor != ''
                            ? 'primary'
                            : 'error'
                        "
                        size="16"
                        >{{ editIcon }}</v-icon
                      >
                      <template v-slot:input>
                        <v-text-field
                          ref="edit-valor"
                          :rules="
                            rules.required.concat([
                              rules.requiredTrim(item.campoValor),
                            ])
                          "
                          v-model.trim="item.campoValor"
                          single-line
                          label="Editar valor"
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </div>
                </template>
                <span>Editar valor</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeModalDatosXPantalla()" tabindex="-1">
            Cerrar
          </v-btn>
          <v-btn
            type="submit"
            form="crud-form"
            color="primary"
            tabindex="-1"
            :loading="exportFileBtn"
            :disabled="!continueToExportIsValid"
            @click="exportArchivoTransferencia()"
          >
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="modalExportarArchivo"
      v-model="modalExportarArchivo"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <v-col cols="12" md="12">
              <span class="pl-1 primary--text"
                >Se generará un archivo de transferencia con el formato
                configurado.</span
              >
            </v-col>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="closeModalExportarArchivoTransferencia()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="to-right"
              text
              :loading="exportFileBtn"
              @click="exportArchivoTransferencia()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="openModalEnviarOrdenes"
      v-model="openModalEnviarOrdenes"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EnviarOrdenesPagoEmail
        @closeAndReload="closeAndReload"
        :registrosSeleccionados="this.registrosSeleccionados"
      ></EnviarOrdenesPagoEmail>
    </v-dialog>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
    <!-- dialog para eliminar op -->
    <v-dialog
      v-if="openModalDeleteOp"
      v-model="openModalDeleteOp"
      max-width="75%"
      @keydown.esc="openModalDeleteOp = false"
      persistent
    >
      <VerOrdenDePagoDialog
        :isDelete="true"
        @closeModalDelete="(openModalDeleteOp = false), applyFilters()"
        :paramsToSeeOpDialog="paramsToDeleteOp"
      ></VerOrdenDePagoDialog>
    </v-dialog>
    <!-- REVERTIR OP -->
    <v-dialog
      v-model="openModalRevertOp"
      v-if="openModalRevertOp"
      @keydown.esc="openModalRevertOp = false"
      max-width="87%"
      persistent
    >
      <RevertirOrdenDePago
        :movProvId="movProvIdToRevert"
        @toggleModalRevertOp="openModalRevertOp = !openModalRevertOp"
      ></RevertirOrdenDePago>
    </v-dialog>
    <!-- MODAL registros de op (recibo/entrega) -->
    <v-dialog
      v-if="isOpenModalRegistros"
      v-model="isOpenModalRegistros"
      max-width="55%"
      @keydown.esc="closeAndReloadRegistro"
      persistent
    >
      <EntregaReciboOP
        @closeAndReloadRegistro="closeAndReloadRegistro"
        :comprobantesToEntregaReciboOP="this.comprobantesToEntregaReciboOP"
        :isEntregaOrRecibo="this.isEntregaOrRecibo"
      ></EntregaReciboOP>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDeleteEntrega"
      :maxWidth="'47%'"
      :isLoading="loadingBtnDeleteEntrega"
      :openDelete.sync="showDeleteModalEntrega"
      @onDeleteItem="confirmDeleteEntrega()"
    />
    <DeleteDialog
      :titleProp="titleDeleteRecibo"
      :maxWidth="'47%'"
      :isLoading="loadingBtnDeleteRecibo"
      :openDelete.sync="showDeleteModalRecibo"
      @onDeleteItem="confirmDeleteRecibo()"
    />
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import helpers from "@/utils/helpers.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import VerOrdenDePagoDialog from "@/components/modules/proveedores/ordenesPago/VerOrdenDePagoDialog.vue";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import EnviarOrdenesPagoEmail from "@/components/modules/proveedores/ordenesPago/EnviarOrdenesPagoEmail";
import EntregaReciboOP from "@/components/modules/proveedores/ordenesPago/EntregaReciboOP.vue";
import RevertirOrdenDePago from "@/components/modules/proveedores/ordenesPago/RevertirOrdenDePago.vue";

export default {
  name: "OrdenesDePago",
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    EnviarOrdenesPagoEmail,
    BuscarProveedor,
    EntregaReciboOP,
    DeleteDialog,
    VerOrdenDePagoDialog,
    RevertirOrdenDePago,
  },
  directives: { mask },
  data: (vm) => ({
    rules: rules,
    helpers: helpers,
    optionCode: enums.webSiteOptions.ORDENES_DE_PAGO,
    seeIcon: enums.icons.SEE,
    deleteIcon: enums.icons.DELETE,
    sendIcon: enums.icons.PAPER_CHECK,
    verticalPointsIcon: enums.icons.THREE_VERTICAL_POINTS,
    receiveIcon: enums.icons.PAPER_MINUS,
    infoIcon: enums.icons.SNB_INFO,
    title: enums.titles.ORDENES_DE_PAGO,
    searchIcon: enums.icons.SEARCH,
    calendarIcon: enums.icons.CALENDAR,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    reverseIcon: enums.icons.REVERSE,
    settingsIcon: enums.icons.SETTINGS,
    editIcon: enums.icons.EDIT,
    registrosSeleccionados: [],
    comprobantesToEntregaReciboOP: [],
    isEntregaOrRecibo: null,
    editOpOptions: [
      { id: 0, title: "Editar solo imputación" },
      { id: 1, title: "Editar" },
    ],
    enviableMail: false,
    soloNoEnviado: false,
    enviablesEmailFiltrados: false,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    empresasLoading: null,
    sucursalOp: null,
    numeroDeOp: null,
    exportFileBtn: false,
    empresas: [],
    valores: [],
    modalExportarArchivo: false,
    empresaSelected: null,
    tipoFechaSelected: {
      id: 2,
      value: "Fecha comprobante",
    },
    tiposFechas: [],
    menuFechaDesde: null,
    fechaDesde: null,
    fechaDesdeSelected: null,
    menuFechaHasta: null,
    fechaHasta: null,
    fechaHastaSelected: null,
    proveedoresLoading: null,
    proveedores: [],
    proveedorSelected: null,
    tiposValor: [],
    tipoValorSelected: null,
    showDeleteModalEntrega: false,
    showDeleteModalRecibo: false,
    loadingBtnDeleteEntrega: false,
    loadingBtnDeleteRecibo: false,
    titleDeleteEntrega:
      "¿Desea eliminar entregas de los registros seleccionados?",
    titleDeleteRecibo:
      "¿Desea eliminar recibos de los registros seleccionados?",
    //soloSinTransfe: false,
    isLoading: false,
    search: "",
    ordenesDePago: [],
    headers: [],
    showFilters: true,
    filtersApplied: [],
    isFormValid: true,
    totalOP: "0.00",
    totalTipoValor: "0.00",
    allowedActions: null,
    canSee: false,
    canRevertirOp: false,
    canEliminarOp: false,
    canEntregaRecibo: false,
    idOp: null,
    disabledCount: 0,
    filtrosGuardados: {},
    canGoToConsultaOP: false,
    entregasOP: [],
    resultsExportExcel: [],
    entregaOPSelected: {
      id: 3,
      value: "Todas",
    },
    entregasRecibo: [],
    entregaReciboSelected: {
      id: 3,
      value: "Todas",
    },
    canExportArchivoTransferencia: false,
    canEnviarEmailMasivo: false,
    existExportFormatoTransferencia: false,
    csvJsonCompleto: [],
    csvJsonToExport: [],
    detalleResponse: [],
    detalleResponse: [],
    existExportArchivoTransferencia: false,
    openModalEnviarOrdenes: false,
    modalDatosXPantalla: false,
    isOpenModalRegistros: false,
    datosXPatalla: [],
    headersDatosXPantalla: [
      { text: "Campo", value: "campoNombre", sortable: false },
      { text: "Valor", value: "campoValor", sortable: false },
      {
        text: "Descripción",
        value: "campoDescripcion",
        align: "end",
        sortable: false,
      },
    ],
    continueToExportIsValid: false,
    canGoToPagosMasivos: false,
    opcionesMenuGrales: [
      { id: 1, title: "Entrega en registros seleccionados", disabled: true },
      { id: 2, title: "Recibo en registros seleccionados", disabled: true },
      {
        id: 3,
        title: "Eliminar entrega en registros seleccionados",
        disabled: true,
      },
      {
        id: 4,
        title: "Eliminar recibo en registros seleccionados",
        disabled: true,
      },
    ],
    opcionesMenuEmail: [
      { id: 1, title: "Enviar por email", disabled: true },
      {
        id: 2,
        title: "Procesos masivos de email",
        disabled: true,
      },
    ],
    opcionesMenuExportar: [
      {
        id: 1,
        title: "Exportar detalle",
        disabled: true,
      },
      {
        id: 2,
        title: "Exportar archivo de transferencias",
        disabled: true,
      },
    ],
    activeBtnExpTransf: false,
    buscarProveedorModal: false,
    openModalDeleteOp: false,
    paramsToDeleteOp: null,
    openModalRevertOp: false,
    movProvIdToRevert: null,
    provCod: null,
    provNom: null,
    provId: null,
  }),
  created() {
    this.setSelects();
    this.setFecha();
  },
  watch: {
    registrosSeleccionados(val) {
      const objToChangeExp = this.opcionesMenuExportar.find(
        (item) => item.id === 2
      );
      const objToChange = this.opcionesMenuEmail.find((item) => item.id === 1);
      if (val.length > 0) {
        if (this.activeBtnExpTransf) {
          objToChangeExp.disabled = false;
        }
        if (this.enviablesEmailFiltrados && this.canEnviarEmailMasivo) {
          objToChange.disabled = false;
        }
      } else {
        objToChangeExp.disabled = true;
        objToChange.disabled = true;
      }
    },
  },
  async mounted() {
    // cargo filtros aplicados si vuelve de la pantalla "VerOrdenDePago"
    if (this.$store.state.filtersOrdenesPago != null) {
      this.empresaSelected = this.$store.state.filtersOrdenesPago.empresa;
      if (this.$store.state.filtersOrdenesPago.prov != null) {
        this.provCod = this.$store.state.filtersOrdenesPago.prov.value2;
        await this.buscarProveedor();
      }
      this.tipoFechaSelected = this.$store.state.filtersOrdenesPago.tipoFecha;
      this.fechaDesde = this.$store.state.filtersOrdenesPago.fechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersOrdenesPago.fechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.proveedorSelected = this.$store.state.filtersOrdenesPago.prov;
      this.tipoValorSelected = this.$store.state.filtersOrdenesPago.tipoValor;
      this.enviableMail = this.$store.state.filtersOrdenesPago.envioMail;
      this.soloNoEnviado = this.$store.state.filtersOrdenesPago.soloNoEnviado;
      this.entregaReciboSelected =
        this.$store.state.filtersOrdenesPago.entregaRecibo;
      this.entregaOPSelected = this.$store.state.filtersOrdenesPago.entregaOP;
      this.sucursalOp = this.$store.state.filtersOrdenesPago.sucursalOp;
      this.numeroDeOp = this.$store.state.filtersOrdenesPago.numeroOp;
      // this.soloSinTransfe =
      //   this.$store.state.filtersOrdenesPago.sinNumTransferenciaCheque;
      this.applyFilters();
    }
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },

  methods: {
    ...mapActions({
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      getDetalleOrdenPago: "proveedores/getDetalleOrdenPago",
      canEditTotalOp: "proveedores/canEditTotalComprobante",
      deleteEntregaReciboDeOP: "proveedores/deleteEntregaReciboDeOP",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod",
      getTiposValoresModuloProveedores:
        "proveedores/getTiposValoresModuloProveedores",
      consultaOrdenesPago: "proveedores/consultaOrdenesPago",
      crearProcesoEnvioMasivoOP: "proveedores/crearProcesoEnvioMasivoOP",
      saveLogEnviosEstandarOrdenesPago:
        "proveedores/saveLogEnviosEstandarOrdenesPago",
      envioEstandarOPRequiereDatosXPantalla:
        "proveedores/envioEstandarOPRequiereDatosXPantalla",
      setAlert: "user/setAlert",
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.VER_ORDEN_DE_PAGO:
            this.canSee = true;
            break;
          case enums.modules.adminSistema.permissions.REVERTIR_ORDEN_DE_PAGO:
            this.canRevertirOp = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_ORDEN_DE_PAGO:
            this.canEliminarOp = true;
            break;
          case enums.modules.adminSistema.permissions
            .CONSULTA_PROCESOS_MASIVOS_OP:
            this.canGoToConsultaOP = true;
            const objToChange = this.opcionesMenuEmail.find(
              (item) => item.id === 2
            );
            objToChange.disabled = false;
            break;
          case enums.modules.adminSistema.permissions.EXPORTAR_ARCHIVO:
            this.canExportArchivoTransferencia = true;
            break;
          case enums.modules.adminSistema.permissions.ENVIO_MASIVO_EMAIL:
            this.canEnviarEmailMasivo = true;
            break;
          case enums.modules.adminSistema.permissions
            .PAGOS_MASIVOS_ORDENES_PAGO:
            this.canGoToPagosMasivos = true;
            break;
          case enums.modules.adminSistema.permissions
            .REGISTRO_ENTREGAS_RECIBOS_OP:
            this.canEntregaRecibo = true;
            break;
        }
      });
    },
    async setSelects() {
      this.tiposFechas = [
        {
          id: 1,
          value: "Fecha contable",
        },
        {
          id: 2,
          value: "Fecha comprobante",
        },
      ];
      this.entregasOP = [
        {
          id: 1,
          value: "Pendiente de entrega",
        },
        {
          id: 2,
          value: "Entregada",
        },
        {
          id: 3,
          value: "Todas",
        },
      ];
      this.entregasRecibo = [
        {
          id: 1,
          value: "Pendiente de Reg. Recibo",
        },
        {
          id: 2,
          value: "Con recibo asociado",
        },
        {
          id: 3,
          value: "Todas",
        },
      ];
      const tiposValores = await this.getTiposValoresModuloProveedores();
      this.tiposValor = tiposValores;
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresas = entidadesFacturantes;
    },
    setFecha() {
      let fecha = new Date();
      this.fechaHasta = fecha.toISOString().substring(0, 10);
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      fecha.setMonth(fecha.getMonth() - 3);
      this.fechaDesde = fecha.toISOString().substring(0, 10);
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
    },
    navigateEditComprobanteOp(id, item) {
      if (id == 0) {
        this.$router.push({
          name: "NuevaOrdenDePago",
          params: {
            mProvId: item.movProvId,
            tipoEdicion: {
              canEditImputacion: true,
              canEditTotal: false,
              mensaje: null,
            },
            empresaSelected: this.empresaSelected
              ? this.empresaSelected.id
              : null,
            provCod: this.proveedorSelected
              ? this.proveedorSelected.value2
              : null,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            routeToGoBack: "OrdenesDePago",
          },
        });
      }
      if (id == 1) this.goToEditOrdenPago(item);
    },
    selectOpcionMenuGrales(id) {
      switch (id) {
        case 1:
          this.goEntregaEnRegistros();
          break;
        case 2:
          this.goReciboEnRegistros();
          break;
        case 3:
          this.goDeleteEntregaEnRegistros();
          break;
        case 4:
          this.goDeleteReciboEnRegistros();
          break;
        default:
          break;
      }
    },
    selectOpcionMenuEmail(id) {
      switch (id) {
        case 1:
          this.enviarMailMasivo();
          break;
        case 2:
          this.goToConsultaProcesosMasivosOP();
          break;
        default:
          break;
      }
    },
    selectOpcionMenuExportar(id) {
      switch (id) {
        case 1:
          this.exportExcel();
          break;
        case 2:
          this.toggleModalExportarArchivoTransferencia();
          break;
        default:
          break;
      }
    },
    goToEntregaIndividual(item) {
      this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
      this.comprobantesToEntregaReciboOP.push(item);
      this.isEntregaOrRecibo = "Entrega";
      this.isOpenModalRegistros = true;
    },
    goToReciboIndividual(item) {
      this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
      this.comprobantesToEntregaReciboOP.push(item);
      this.isEntregaOrRecibo = "Recibo";
      this.isOpenModalRegistros = true;
    },
    goEntregaEnRegistros() {
      if (this.registrosSeleccionados.length > 0) {
        this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
        this.comprobantesToEntregaReciboOP = this.registrosSeleccionados;
        this.isEntregaOrRecibo = "Entrega";
        this.isOpenModalRegistros = true;
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar comprobantes",
        });
      }
    },
    goReciboEnRegistros() {
      if (this.registrosSeleccionados.length > 0) {
        this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
        this.comprobantesToEntregaReciboOP = this.registrosSeleccionados;
        this.isEntregaOrRecibo = "Recibo";
        this.isOpenModalRegistros = true;
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar comprobantes",
        });
      }
    },
    async goDeleteEntregaEnRegistros() {
      if (this.registrosSeleccionados.length > 0) {
        this.showDeleteModalEntrega = true;
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar comprobantes",
        });
      }
    },
    async confirmDeleteEntrega() {
      this.loadingBtnDeleteEntrega = true;
      const arrayIds = this.registrosSeleccionados.map((x) => x.movProvId);
      const entfac = this.registrosSeleccionados[0].entFacIdXTComp;
      const data = {
        fecha: null,
        obs: null,
        entFacId: entfac,
        tipo: "Entrega",
        mProvIds: arrayIds,
      };
      const response = await this.deleteEntregaReciboDeOP(data);
      if (response) {
        this.setAlert({
          type: "success",
          message: "Entregas de op eliminadas con éxito.",
        });
        this.showDeleteModalEntrega = false;
        this.loadingBtnDeleteEntrega = false;
        this.applyFilters();
      }
    },
    async confirmDeleteRecibo() {
      this.loadingBtnDeleteRecibo = true;
      const arrayIds = this.registrosSeleccionados.map((x) => x.movProvId);
      const entfac = this.registrosSeleccionados[0].entFacIdXTComp;
      const data = {
        fecha: null,
        obs: null,
        entFacId: entfac,
        tipo: "Recibo",
        mProvIds: arrayIds,
      };
      const response = await this.deleteEntregaReciboDeOP(data);
      if (response) {
        this.setAlert({
          type: "success",
          message: "Recibo de op eliminadas con éxito.",
        });
        this.showDeleteModalRecibo = false;
        this.loadingBtnDeleteRecibo = false;
        this.applyFilters();
      }
    },
    async goDeleteReciboEnRegistros() {
      if (this.registrosSeleccionados.length > 0) {
        this.showDeleteModalRecibo = true;
      } else {
        this.setAlert({
          type: "warning",
          message: "Debe seleccionar comprobantes",
        });
      }
    },
    empresaSelectedChange() {
      if (!this.empresaSelected) {
        this.enviableMail = false;
        this.soloNoEnviado = false;
      }
    },
    enviableMailChange() {
      if (!this.enviableMail) this.soloNoEnviado = false;
    },
    customFilterEmpresas(item, queryText, itemText) {
      return (
        item.nombreCuit ||
        item.cuit.indexOf(queryText) > -1 ||
        item.value.indexOf(queryText) > -1
      );
    },
    async applyFilters() {
      this.registrosSeleccionados = [];
      this.$store.state.filtersOrdenesPago = null;
      if (this.tipoValorSelected != null) {
        this.headers = [
          {
            text: "Fecha",
            value: "fecha",
            align: "start",
            sortable: false,
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            align: "start",
            sortable: false,
          },
          {
            text: "Número",
            value: "numeroOp",
            sortable: false,
          },
          {
            text: "Total OP",
            value: `totalOpString`,
            align: "end",
            sortable: false,
          },
          {
            text: "N° tipo de valor",
            value: "tipoValor.numero",
            align: "start",
            sortable: false,
          },
          {
            text: "Importe tipo de valor",
            value: `tipoValor.totalTipoValorString`,
            align: "end",
            sortable: false,
          },
          {
            text: "Fecha tipo de valor",
            value: "tipoValor.fechaTipoValor",
            align: "start",
            sortable: false,
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
            align: "end",
            width: "12%",
          },
        ];
      } else {
        this.headers = [
          {
            text: "Fecha",
            value: "fecha",
            sortable: false,
          },
          {
            text: "Proveedor",
            value: "proveedor.nombre",
            sortable: false,
          },
          {
            text: "Número",
            value: "numeroOp",
            sortable: false,
          },
          {
            text: "Total OP",
            value: "totalOpString",
            align: "end",
            sortable: false,
          },
          {
            text: "Acciones",
            value: "actions",
            sortable: false,
            align: "end",
            width: "12%",
          },
        ];
      }
      this.isLoading = true;
      this.customizeFiltersApplied();
      this.showFilters = false;
      const filters = {
        empresaId: this.empresaSelected?.id,
        tipoFecha: this.tipoFechaSelected.id,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        provId: this.proveedorSelected?.id,
        tipoValorId: this.tipoValorSelected?.id,
        enviablesEmail: this.empresaSelected ? this.enviableMail : false,
        noEnviadosXEmail:
          this.enviableMail && this.empresaSelected
            ? this.soloNoEnviado
            : false,
        entregaOP: this.entregaOPSelected?.id,
        entregaRecibo: this.entregaReciboSelected?.id,
        sucursalOp: this.sucursalOp,
        numeroOp: this.numeroDeOp,
        //sinNumTransferenciaCheque: this.soloSinTransfe,
      };
      this.filtrosGuardados = {
        empresa: this.empresaSelected,
        tipoFecha: this.tipoFechaSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        prov: this.proveedorSelected,
        tipoValor: this.tipoValorSelected,
        envioMail: this.enviableMail,
        soloNoEnviado: this.soloNoEnviado,
        entregaOP: this.entregaOPSelected ?? {},
        entregaRecibo: this.entregaReciboSelected ?? {},
        sucursalOp: this.sucursalOp,
        numeroOp: this.numeroDeOp,
        //sinNumTransferenciaCheque: this.soloSinTransfe,
      };
      this.enviablesEmailFiltrados = this.enviableMail;
      try {
        const response = await this.consultaOrdenesPago(filters);
        this.ordenesDePago = response.ordenesPago;
        if (this.ordenesDePago.length > 0) {
          const objToChange = this.opcionesMenuExportar.find(
            (item) => item.id === 1
          );
          objToChange.disabled = false;
        }
        this.totalOP = response.sumaOrdenesPagoString;
        if (this.tipoValorSelected != null) {
          this.totalTipoValor = response.sumaTipoValoresString;
          this.existExportArchivoTransferencia =
            response.existExportFormatoTransferencia;
        }
        if (
          this.existExportArchivoTransferencia &&
          this.canExportArchivoTransferencia &&
          this.ordenesDePago.length > 0 &&
          this.tipoValorSelected != null
        )
          this.activeBtnExpTransf = true;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
      this.habilitarOpcionesMenuGrales();
    },
    habilitarOpcionesMenuGrales() {
      const objToChangeEntrega = this.opcionesMenuGrales.find(
        (item) => item.id === 1
      );
      const objToChangeRecibo = this.opcionesMenuGrales.find(
        (item) => item.id === 2
      );
      const objToChangeDeleteEntrega = this.opcionesMenuGrales.find(
        (item) => item.id === 3
      );
      const objToChangeDeleteRecibo = this.opcionesMenuGrales.find(
        (item) => item.id === 4
      );
      if (this.empresaSelected != undefined && this.empresaSelected != null) {
        if (this.canEntregaRecibo) {
          if (this.entregaOPSelected.id == 1) {
            objToChangeEntrega.disabled = false;
            objToChangeDeleteEntrega.disabled = true;
          } else if (this.entregaOPSelected.id == 2) {
            objToChangeEntrega.disabled = true;
            objToChangeDeleteEntrega.disabled = false;
          } else if (this.entregaOPSelected.id == 3) {
            objToChangeEntrega.disabled = true;
            objToChangeDeleteEntrega.disabled = true;
          }
          if (this.entregaReciboSelected.id == 1) {
            objToChangeRecibo.disabled = false;
            objToChangeDeleteRecibo.disabled = true;
          } else if (this.entregaReciboSelected.id == 2) {
            objToChangeRecibo.disabled = true;
            objToChangeDeleteRecibo.disabled = false;
          } else if (this.entregaReciboSelected.id == 3) {
            objToChangeRecibo.disabled = true;
            objToChangeDeleteRecibo.disabled = true;
          }
        } else {
          objToChangeEntrega.disabled = true;
          objToChangeRecibo.disabled = true;
          objToChangeDeleteRecibo.disabled = true;
          objToChangeDeleteEntrega.disabled = true;
        }
      } else {
        objToChangeEntrega.disabled = true;
        objToChangeRecibo.disabled = true;
        objToChangeDeleteRecibo.disabled = true;
        objToChangeDeleteEntrega.disabled = true;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.empresaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "empresaSelected",
          label: "Empresa",
          model: this.empresaSelected.value,
        });
      }
      if (this.proveedorSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "proveedorSelected",
          label: "Proveedor",
          model: this.proveedorSelected.value1,
        });
      }
      if (this.tipoValorSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "tipoValorSelected",
          label: "Tipo de valor",
          model: this.tipoValorSelected.value,
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "tipoFecha",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value,
        });
      }
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(4, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.fechaDesdeSelected,
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.fechaHastaSelected,
        });
      }
      // if (this.soloSinTransfe) {
      //   this.filtersApplied.splice(8, 1, {
      //     key: "entregaRecibo",
      //     label: "Sin n° de transfe./cheque",
      //     model: "SI",
      //   });
      // }
      if (this.entregaOPSelected) {
        this.filtersApplied.splice(6, 1, {
          key: "entregaOP",
          label: "Entrega de OP",
          model: this.entregaOPSelected.value,
        });
      }
      if (this.entregaReciboSelected) {
        this.filtersApplied.splice(7, 1, {
          key: "entregaRecibo",
          label: "Entrega de recibo",
          model: this.entregaReciboSelected.value,
        });
      }
      if (this.enviableMail) {
        const conEmail = "SI";
        this.filtersApplied.splice(8, 1, {
          key: "enviablesEmail",
          label: "Solo enviables por email",
          model: conEmail,
        });
      } else {
        const conEmail = "NO";
        this.filtersApplied.splice(8, 1, {
          key: "enviablesEmail",
          label: "Solo enviables por email",
          model: conEmail,
        });
      }
      if (this.soloNoEnviado && this.enviableMail) {
        this.filtersApplied.splice(9, 1, {
          key: "soloNoEnviado",
          label: "Emails no enviados",
          model: "SI",
        });
      }
      if (this.sucursalOp) {
        this.filtersApplied.splice(10, 1, {
          key: "sucursal",
          label: "Sucursal",
          model: this.sucursalOp,
        });
      }
      if (this.numeroDeOp) {
        this.filtersApplied.splice(11, 1, {
          key: "numeroDeOp",
          label: "Número",
          model: this.numeroDeOp,
        });
      }
    },
    selectAllToggle() {
      if (this.registrosSeleccionados.length < this.ordenesDePago.length) {
        this.registrosSeleccionados = this.ordenesDePago;
      } else {
        this.registrosSeleccionados = [];
      }
    },
    enviarMailMasivo() {
      this.openModalEnviarOrdenes = true;
    },
    closeAndReload() {
      this.openModalEnviarOrdenes = false;
      this.applyFilters();
    },
    goToDeleteComprobante(item) {
      this.paramsToDelete = {
        movProvId: item.movProvId,
        isCtaCte: false,
        monedaToDetalle: null,
        fechaCotizaToDetalle: null,
      };
      this.paramsToDeleteOp = this.paramsToDelete;
      this.openModalDeleteOp = true;
    },
    goToRevertOp(item) {
      this.movProvIdToRevert = item.movProvId;
      this.openModalRevertOp = true;
    },
    async closeAndReloadRegistro() {
      this.isOpenModalRegistros = false;
      this.comprobantesToEntregaReciboOP = [];
      if (this.$store.state.filtersOrdenesPago != null) {
        this.empresaSelected = this.$store.state.filtersOrdenesPago.empresa;
        if (this.$store.state.filtersOrdenesPago.prov != null) {
          this.provCod = this.$store.state.filtersOrdenesPago.prov.value2;
          await this.buscarProveedor();
        }
        this.tipoFechaSelected = this.$store.state.filtersOrdenesPago.tipoFecha;
        this.fechaDesde = this.$store.state.filtersOrdenesPago.fechaDesde;
        this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
        this.fechaHasta = this.$store.state.filtersOrdenesPago.fechaHasta;
        this.fechaHastaSelected = this.formatDate(this.fechaHasta);
        this.proveedorSelected = this.$store.state.filtersOrdenesPago.prov;
        this.tipoValorSelected = this.$store.state.filtersOrdenesPago.tipoValor;
        this.enviableMail = this.$store.state.filtersOrdenesPago.envioMail;
        this.soloNoEnviado = this.$store.state.filtersOrdenesPago.soloNoEnviado;
        this.entregaReciboSelected =
          this.$store.state.filtersOrdenesPago.entregaRecibo;
        this.entregaOPSelected = this.$store.state.filtersOrdenesPago.entregaOP;
        this.sucursalOp = this.$store.state.filtersOrdenesPago.sucursalOp;
        this.numeroDeOp = this.$store.state.filtersOrdenesPago.numeroOp;
      }
      this.applyFilters();
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    async findProveedor(proveedor) {
      this.proveedorSelected = {
        id: proveedor.provId,
        value1: proveedor.provNom,
        value2: proveedor.provCod,
      };
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
    },
    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente",
            });
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1;
          this.cuit = this.proveedorSelected.cuit;
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    async exportArchivoTransferencia() {
      this.exportFileBtn = true;
      try {
        const data = {
          mpValIds: this.registrosSeleccionados.map((x) => x.mpValId),
          tvId: this.tipoValorSelected?.id,
          datosXPantalla: this.datosXPatalla,
        };
        const response = await this.saveLogEnviosEstandarOrdenesPago(data);
        if (response.status === 200) {
          this.downloadFile(response.data);
          this.setAlert({
            type: "success",
            message: "Archivo generado con éxito.",
          });
          this.exportFileBtn = false;
          this.closeModalDatosXPantalla();
          this.closeModalExportarArchivoTransferencia();
        }
      } catch (error) {
        this.exportFileBtn = false;
      }
    },
    downloadFile(data) {
      const archivoBytes = new Uint8Array(
        atob(data.resultArchivo)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([archivoBytes], {
        type: "text/plain",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = data.nombreArchivo;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    closeModalExportarArchivoTransferencia() {
      this.modalExportarArchivo = false;
    },
    async toggleModalExportarArchivoTransferencia() {
      // Primero valido que el proceso de exportación no pida datos por pantalla
      try {
        const response = await this.envioEstandarOPRequiereDatosXPantalla(
          this.tipoValorSelected?.id
        );
        if (response.length > 0) {
          this.datosXPatalla = response;
          this.modalDatosXPantalla = true;
        } else this.modalExportarArchivo = true; // Si no pide datos por pantalla, abro el modal para exportar
      } catch (error) {}
    },
    seeComprobante(id, email) {
      this.$router.push({
        name: "VerOrdenDePago",
        params: {
          movProvId: id,
          email: email,
          goBackName: "OrdenesDePago",
        },
      });
      this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
    },
    async goToEditOrdenPago(item) {
      this.$store.state.filtersOrdenesPago = this.filtrosGuardados;
      this.$router.push({
        name: "NuevaOrdenDePago",
        params: {
          mProvId: item.movProvId,
          tipoEdicion: await this.canEditTotalOp(item.movProvId),
          empresaSelected: item.entFacIdXTComp,
          provCod: item.provCod,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          routeToGoBack: "OrdenesDePago",
        },
      });
    },
    goToOrdenPago(mProvId) {
      this.$router.push({
        name: "NuevaOrdenDePago",
        params: {
          mProvId: mProvId,
          empresaSelected: this.empresaSelected
            ? this.empresaSelected.id
            : null,
          provCod: this.proveedorSelected
            ? this.proveedorSelected.value2
            : null,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          routeToGoBack: "OrdenesDePago",
        },
      });
    },
    goToPagosMasivos(mProvId) {
      this.$router.push({
        name: "PagosMasivosOrdenesPago",
        params: {
          mProvId: mProvId,
          empresaSelected: this.empresaSelected
            ? this.empresaSelected.id
            : null,
          provCod: this.proveedorSelected
            ? this.proveedorSelected.value2
            : null,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
        },
      });
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    exportExcel() {
      let result = [];
      this.ordenesDePago.forEach((x) => {
        let row = {
          ["Fecha"]: x.fecha,
          ["Fecha contable"]: x.fechaContable,
          ["Proveedor"]: x.proveedor?.nombre,
          ["Número"]: x.numeroOp,
          ["CUIT"]: x.cuit,
          ["CBU"]: x.proveedor?.cbu,
        };
        if (this.tipoValorSelected != null) {
          row["N° tipo de valor"] = x.tipoValor?.numero;
          row["Importe tipo de valor"] = x.tipoValor?.totalTipoValor;
          row["Fecha del tipo de valor"] = x.tipoValor?.fechaTipoValor;
          row["Total OP"] = x.totalOp;
        } else {
          row["Total OP"] = x.totalOp;
        }
        result.push(row);
      });
      let formato = {
        filas: result,
        hoja: "Resultado",
      };
      helpersExcel.excelExport(formato, "Ordenes de pago");
    },
    goToConfigOrdenesPago() {
      this.$router.push({
        name: "ConfiguracionOrdenesPago",
      });
    },
    goToConsultaProcesosMasivosOP() {
      this.$router.push({
        name: "ConsultaProcesosMasivosOP",
      });
    },
    openEditDialog(originalValue) {
      this.previousValue = originalValue;
    },
    closeModalDatosXPantalla() {
      this.modalDatosXPantalla = false;
    },
    saveEditValor(item) {
      if (this.$refs["edit-valor"].validate()) {
        item.campoValor;
      }
      this.setAlert({ type: "success", message: "Actualizado con éxito" });
      this.datosXPatalla.forEach((element) => {
        if (element.campoValor === "" || element.campoValor === null) {
          this.continueToExportIsValid = false;
          return;
        } else this.continueToExportIsValid = true;
      });
    },
    cancelEditValor(item) {
      item.campoValor = this.previousValue;
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
</style>
